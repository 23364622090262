import '@angular/localize/init';

/**
 * Declare Amazon Quicksight Dashboard import
 */
import 'amazon-quicksight-embedding-sdk';

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
// Run `npm install --save classlist.js`.
import 'classlist.js';
/** IE9, IE10 and IE11 requires all of the following polyfills. */
import 'core-js/es/array';
import 'core-js/es/date';
import 'core-js/es/function';
import 'core-js/es/map';
import 'core-js/es/math';
import 'core-js/es/number';
import 'core-js/es/object';
import 'core-js/es/parse-float';
import 'core-js/es/parse-int';

/** Evergreen browsers require these. */
import 'core-js/es/reflect';
import 'core-js/es/regexp';
import 'core-js/es/set';
import 'core-js/es/string';
import 'core-js/es/symbol';
import 'core-js/es/weak-map';
import 'core-js/proposals/reflect-metadata';

/** Required to support DOM manipulation methods in IE10 and IE11 */
import 'mdn-polyfills/Element.prototype.closest';
import 'mdn-polyfills/Element.prototype.matches';
/** JSON Object Mapper requires the following */
import 'reflect-metadata';
import 'ws';

/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js/dist/zone';

declare global {
  interface Window {
    global: any;
  }
}
window.global = window;
